.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  font-family: 'Quicksand', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.Dawning {
  font-family: 'Dawning of a New Day', cursive;
}

a:hover {
  color: #d39e00 !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  border: 1.5px solid #d39e00;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}