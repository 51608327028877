* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

body {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #393c41;
}

ul {
  list-style: none;
}